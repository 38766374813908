import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import AboutPanel from "../components/about-panel"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import AppsPanel from "../components/apps-panel"


const HippaPage = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="HIPAA Compliant Software Development Experts" />
        <Hero title="On-Demand HIPAA Compliant Software Development Experts" description="Our engineers have years of experience in developing health care applications, so you can rest assured that your project will be in good hands." image="pages/hipaa-hero.jpg" />
        <AboutPanel title="I can help you develop your health care software." description="Hire high-performing, on-demand software engineers with health care app development" image="pages/hipaa-developer.jpg"  />
        <AppsPanel description="Looking to create a HIPAA compliant app? Don't go it alone! Hire our experts. We have a world-class team of senior engineers with experience in health care app development. Plus, we're always available on demand so you can scale up your business quickly and easily. Contact us today to get started!" />

      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default HippaPage
